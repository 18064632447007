import React from 'react'
import Seo from '../../components/molecules/seo'
import Layout from '../../components/molecules/layout'
import SignUpModal from '../../components/sign-up-modal'
import { ClerkLoaded, SignedIn } from '@clerk/clerk-react'
import { Link } from 'gatsby'

const HowToUse = (props) => {
  const title = 'how to use'

  return (
    <Layout>
      <Seo title={title} />
      <section className="hero is-primary">
        <div className="hero-body">
          <h1 className="page-title">{title}</h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-full">
              <main className="content">
                <h2>basic usage</h2>
                <p>
                  signup for phonetonote, install a client plugin, and send us
                  messages.
                </p>
                <ClerkLoaded>
                  <SignedIn>
                    <p>
                      visit{' '}
                      <a href="https://dashboard.phonetonote.com">
                        your dashboard
                      </a>{' '}
                      for detailed installation instructions.
                    </p>
                  </SignedIn>
                </ClerkLoaded>
                <p>
                  the next time you log into{' '}
                  <Link to="/pages/clients">a supported client</Link>, we'll
                  sync your messages to your daily notes.
                </p>

                <h2>link parsing</h2>
                <p>
                  when you send us a url, we see if it has any{' '}
                  <a href="https://ogp.me/">open graph metadata</a>. if it does,
                  we attach it to your message.
                </p>
                <h2>date parsing</h2>
                <p>
                  phonetonote uses a{' '}
                  <a href="https://github.com/mojombo/chronic">
                    natural language date parser
                  </a>{' '}
                  to transform human and machine date strings into their
                  appropriate daily page reference.
                </p>
                <p>
                  we look for dates in text between two semicolons{' '}
                  <strong>;like this;</strong>
                </p>
                <p>
                  and transform a string like ;oct 1; to [[October 1st, 2021]]
                </p>
                <p>we can parse a variety of formats such as:</p>
                <ul>
                  <li>;next tuesday;</li>
                  <li>;1 year from now;</li>
                  <li>;2 weeks;</li>
                  <li>;jan 10;</li>
                  <li>;10/30/2020;</li>
                  <li>;15-06-2020;</li>
                  <li>;2013-08-01T19:30:00.345-07:00;</li>
                </ul>
                <ClerkLoaded>
                  <SignUpModal />
                </ClerkLoaded>
              </main>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HowToUse
